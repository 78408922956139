import { Company, CompanyGroup } from '@erp_core/erp-types/dist/modules/admin';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import { VendorRegistration } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  CardBody,
  CardHeader,
  FormField,
  renderCardComponent,
  renderFileViewerUploader,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';

export function RenderVendorRegistration({
  data,
  setVendor,
  useFileTransfer,
  company,
  companyGroup,
}: {
  data: Vendor;
  setVendor: (s: Vendor) => Promise<Vendor>;
  useFileTransfer: UseFileTransfer;
  company: Company;
  companyGroup: CompanyGroup;
}): JSX.Element {
  const VendorRegistrationCard = renderCardComponent();
  const FileViewerUploader = renderFileViewerUploader();

  const formFields: FormField<any>[] = [
    {
      property: 'regDocumentType',
      type: 'select',
      required: true,
      options: [
        { text: 'Select', value: '' },
        { text: 'gst', value: 'gst' },
        { text: 'pan', value: 'pan' },
        { text: 'tin', value: 'tin' },
        { text: 'iec', value: 'iec' },
        { text: 'udyog-aadhar(MSME)', value: 'udyog-aadhar(MSME)' },
        {
          text: 'factory-license',
          value: 'factory-license',
        },
        { text: 'peso', value: 'peso' },
        { text: 'rent-agreement', value: 'rent-agreement' },
        {
          text: 'certificate-of-incorporation',
          value: 'certificate-of-incorporation',
        },
        {
          text: 'articles-of-association',
          value: 'articles-of-association',
        },
        { text: 'duns-registeration', value: 'duns-registeration' },
        { text: 'iso-9001-certificate', value: 'iso-9001-certificate' },
        { text: 'iso-10002-certificate', value: 'iso-10002-certificate' },
        {
          text: 'iso-14001-certificate',
          value: 'iso-14001-certificate',
        },
        { text: 'iso-22008-certificate', value: 'iso-22008-certificate' },
        { text: 'iso-26000-certificate', value: 'iso-26000-certificate' },
        { text: 'iso-27001-certificate', value: 'iso-27001-certificate' },
        {
          text: 'iso-28000-certificate',
          value: 'iso-28000-certificate',
        },
        { text: 'iso-31000-certificate', value: 'iso-31000-certificate' },
        { text: 'iso-37001-certificate', value: 'iso-37001-certificate' },
        { text: 'customized', value: 'customized' },
      ],
      label: 'Document Type',
    },
    {
      property: 'customizeDocName',
      type: 'input',
      label: 'Document Name',
      dependentOn: (formData) => {
        return formData.regDocumentType === 'customized';
      },
    },
    {
      property: 'regDate',
      type: 'date',
      label: 'Date Of Registration',
    },
    {
      property: 'validUpto',
      type: 'date',
      label: 'Valid UpTo',
    },
    {
      property: 'accountNo',
      type: 'input',
      required: true,
      label: 'Account Number',
    },
  ];

  const addVendorRegistration = async (regData: any) => {
    const cnt: VendorRegistration = {
      regDocumentType: regData.regDocumentType,
      regDate: regData.regDate,
      validUpto: regData.validUpto,
      accountNo: regData.accountNo,
      customizeDocName: regData.customizeDocName || '',
      document: '',
    };
    const finalData = {
      id: data.id,
      details: {
        ...data.details,
        vendorRegistration: data.details?.vendorRegistration
          ? [...data.details.vendorRegistration, cnt]
          : [cnt],
      },
    };
    await setVendor(finalData as Vendor);
  };

  const editVendorRegistration = async (regToUpdate: VendorRegistration) => {
    // if (regToUpdate) {
    //   regToUpdate.regDocumentType = regToUpdate.regDocumentType;
    //   regToUpdate.regDate = regToUpdate.regDate;
    //   regToUpdate.validUpto = regToUpdate.validUpto;
    //   regToUpdate.accountNo = regToUpdate.accountNo;
    //   regToUpdate.customizeDocName = regToUpdate.customizeDocName;
    //   regToUpdate.document = regToUpdate.document;
    // }
    // Disabled this as it looks fishy
    // const finalData = {
    //   id: data.id,
    //   details: {
    //     ...data.details,
    //     vendorRegistration: data.details.vendorRegistration,
    //   },
    // };
    // await setVendor(finalData as Vendor);
  };

  // const renderEditVendorRegistration = (regdata: any) => {
  //   const Form = renderFormV2<any>({
  //     style: 'md:w-1/2',
  //     fieldsData: formFields,
  //     initialFormState: {
  //       regDocumentType: regdata.regDocumentType || '',
  //       validUpto: regdata.validUpto || '',
  //       accountNo: regdata.accountNo || '',
  //       regDate: regdata.regDate || '',
  //       customizeDocName: regdata.customizeDocName || '',
  //     },
  //     onSubmit: async (form) => {
  //       await editVendorRegistration(form);
  //       setModalState((ms) => ({ ...ms, visible: false }));
  //     },
  //     mapTToU: (b) => b,
  //   });
  //   setModalState({
  //     visible: true,
  //     content: <Form />,
  //     title: 'Edit Registration',
  //   });
  // };

  // const RegistrationAction = ({ r, idx }: { r: any; idx: number }) => {
  //   return (
  //     <>
  //       <button
  //         type='button'
  //         className='h-6 w-6'
  //         onClick={() =>
  //           renderEditVendorRegistration({
  //             regDocumentType: r.regDocumentType,
  //             validUpto: r.validUpto,
  //             accountNo: r.accountNo,
  //             regDate: r.regDate,
  //             customizeDocName: r.customizeDocName,
  //             idx,
  //           })
  //         }
  //       >
  //         Edit
  //       </button>
  //       <br />
  //     </>
  //   );
  // };

  const VendorRegistrationCardHeader: CardHeader = {
    title: 'Registration Details',
    actions: [
      {
        type: 'button',
        button: {
          name: 'Add',
          behaviour: 'modal',
          modal: {
            title: 'Add Address',
            content: ({ onClose }) => {
              const Form = renderFormV2<any>({
                style: 'md:w-1/2',
                fieldsData: formFields,
                initialFormState: {
                  regDocumentType: '',
                  validUpto: '',
                  accountNo: '',
                  regDate: '',
                  customizeDocName: '',
                },
                onSubmit: async (data) => {
                  await addVendorRegistration(data);
                  onClose();
                },
                mapTToU: (b) => b,
              });

              return <Form />;
            },
          },
        },
      },
    ],
  };

  function showuploadDoc(r: VendorRegistration, index: number) {
    if (r) {
      const regToUpdate: VendorRegistration | undefined = data.details
        ?.vendorRegistration
        ? data.details.vendorRegistration[index]
        : undefined;
      console.log('regToUpdate', regToUpdate);
      return (
        <div>
          {!r.document ? 'Pending Document' : 'Document'}{' '}
          <FileViewerUploader
            mode='upload'
            url={r.document || ''}
            useFileTransfer={useFileTransfer}
            path={`${companyGroup.id}/${
              company.id
            }/vendor-data/registration/${moment()
              .unix()
              .toString()}`.replaceAll(' ', '-')}
            type='private'
            onUpload={async (path) => {
              let doc = r.document || '';
              console.log('path.url', path.url);
              if (r) {
                doc = path.url;
                console.log('path.url', path.url);
                if (regToUpdate) {
                  regToUpdate.regDocumentType =
                    r.regDocumentType || regToUpdate.regDocumentType;
                  regToUpdate.regDate = r.regDate || regToUpdate.regDate;
                  regToUpdate.validUpto = r.validUpto || regToUpdate.validUpto;
                  regToUpdate.accountNo = r.accountNo || regToUpdate.accountNo;
                  regToUpdate.customizeDocName =
                    r.customizeDocName || regToUpdate.customizeDocName;
                  regToUpdate.document = doc;
                }
                await setVendor({
                  id: data.id,
                  details: {
                    ...data.details,
                    vendorRegistration: data.details.vendorRegistration,
                  },
                } as Vendor);
              }
            }}
          />
        </div>
      );
    }
    return <div></div>;
  }

  const VendorRegistrationTable = renderTableComponent();
  const vendorRegistrationTableHeader: TableHeader = [
    [
      { name: 'Registration' },
      // { name: 'Customized Document Name' },
      { name: 'Type Number/Account Number' },
      { name: 'Date of Registration / Valid Upto' },
      { name: 'Documents' },
      // { name: 'Action' },
    ],
  ];

  const tableBody: TableBody =
    data.details?.vendorRegistration?.map((g, i) => {
      return {
        rowData: {
          vendorRegistration: g,
        },
        cells: [
          {
            value: (
              <>
                {g.regDocumentType || ''}
                {g.customizeDocName ? (
                  <div className='font-semibold'>{g.customizeDocName}</div>
                ) : null}
              </>
            ),
          },
          // { value: g.customizeDocName || '-' },
          { value: g.accountNo || '' },
          { value: g.validUpto || '' },
          { value: showuploadDoc(g, i) },
        ],
      };
    }) || [];

  const VendorRegistrationCardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <VendorRegistrationTable
        header={vendorRegistrationTableHeader}
        body={tableBody}
        actions={[
          {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              title: 'Edit',
              content: ({ data: { vendorRegistration }, onClose }) => {
                const regdata = vendorRegistration as VendorRegistration;

                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: formFields,
                  initialFormState: {
                    regDocumentType: regdata.regDocumentType || '',
                    validUpto: regdata.validUpto || '',
                    accountNo: regdata.accountNo || '',
                    regDate: regdata.regDate || '',
                    customizeDocName: regdata.customizeDocName || '',
                  },
                  onSubmit: async (form) => {
                    await editVendorRegistration(form);
                    onClose();
                  },
                  mapTToU: (b) => b,
                });

                return <Form />;
              },
            },
            show: () => true,
          },
        ]}
      />
    ),
  };

  return (
    <div>
      <VendorRegistrationCard
        body={VendorRegistrationCardBody}
        header={VendorRegistrationCardHeader}
      />
    </div>
  );
}
