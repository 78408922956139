import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { ShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import {
  Card,
  ColumnizedContent,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { LocationContext } from '../../../../../../contexts/location';
import { UseCompanies } from '../../../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseUserAuthorization } from '../../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UsePublicTransfer } from '../../../../../../hooks/file-transfer/use-public-transfer';
import { UseDepartments } from '../../../../../../hooks/hrd/department/use-departments';
import { UseSalaryParams } from '../../../../../../hooks/hrd/salary-parameter/use-salary-params';
import { UseShiftSchedules } from '../../../../../../hooks/planning/shift-schedule/use-shift-schedules';

export function renderEmployeeProfile1({
  usePublicTransfer,
  useUserAuthorization,
  useEmployeeCategories,
  useCompanies,
  useSalaryParams,
  useDepartments,
  useShiftSchedules,
}: {
  usePublicTransfer: UsePublicTransfer;
  useUserAuthorization: UseUserAuthorization;
  useEmployeeCategories: UseEmployeeCategories;
  useCompanies: UseCompanies;
  useSalaryParams: UseSalaryParams;
  useDepartments: UseDepartments;
  useShiftSchedules: UseShiftSchedules;
}) {
  const CardComponent = renderCardComponent();
  return function EmployeeProfile1({
    id,
    companyGroupSetting,
    emp,
    setEmployee,
    loading,
    isHr,
  }: {
    id: string;
    emp: EmployeeProfileType;
    companyGroupSetting?: CompanyGroupSetting;
    loading: boolean;
    setEmployee: (s: EmployeeProfileType) => void;
    isHr: boolean;
  }): JSX.Element {
    const { get: getAllowedActions } = useUserAuthorization();
    const { company, companyGroup } = useContext(LocationContext);
    // const { data: schedules, getAll: getSchedules } = useShiftSchedules();

    // const [showModal, setShowModal] = useState<boolean>(false);
    const sisLocations =
      companyGroupSetting?.details?.companyGroup?.location?.sisterLocations ||
      {};

    const sisLocationNames = Object.keys(sisLocations);
    const employeeProfileInitialData: Card = {
      header: {
        title: `Employee Profile: ${emp?.name}`,
        actions: [
          {
            auth: 'UI:EDIT-EMP-PROFILE:VIEW',
            type: 'button',
            button: {
              name: 'Layoff Salary Rules',
              behaviour: 'modal',
              modal: {
                title: 'Edit Layoff Salary Rules',
                content: ({ onClose }) => {
                  const Form = renderFormV2<any>({
                    style: 'md:w-1/2',
                    fieldsData: [
                      {
                        property: 'onAttendance',
                        type: 'select',
                        required: true,
                        options: [
                          { value: '', text: 'select' },
                          { value: 'full-day-pay', text: 'full-day-pay' },
                          { value: '75%-pay', text: '75%-pay' },
                          { value: '50%-pay', text: '50%-pay' },
                          { value: 'no-pay', text: 'no-pay' },
                        ],
                      },
                      {
                        property: 'onLayOffLeave',
                        type: 'select',
                        required: true,
                        options: [
                          { value: '', text: 'select' },
                          { value: '50%-pay', text: '50%-pay' },
                          { value: 'no-pay', text: 'no-pay' },
                        ],
                      },
                      {
                        property: 'onNonLayOffLeave',
                        type: 'select',
                        required: true,
                        options: [
                          { value: '', text: 'select' },
                          { value: 'full-day-pay', text: 'full-day-pay' },
                          { value: '75%-pay', text: '75%-pay' },
                          { value: '50%-pay', text: '50%-pay' },
                          { value: 'no-pay', text: 'no-pay' },
                        ],
                      },
                      {
                        property: 'calculatedAgainst',
                        type: 'select',
                        required: true,
                        options: [
                          { value: '', text: 'select' },
                          { value: 'basic-da', text: 'basic-da' },
                          { value: 'ctc', text: 'ctc' },
                        ],
                      },
                    ],
                    initialFormState: {
                      onAttendance:
                        emp.details?.layoffSalaryRules?.onAttendance || '',
                      onLayOffLeave:
                        emp?.details?.layoffSalaryRules?.onLayOffLeave || '',
                      onNonLayOffLeave:
                        emp?.details?.layoffSalaryRules?.onNonLayOffLeave || '',
                      calculatedAgainst:
                        emp?.details?.layoffSalaryRules?.calculatedAgainst ||
                        '',
                    },
                    onSubmit: async (data) => {
                      await setEmployee({
                        id: emp.id,
                        details: {
                          layoffSalaryRules: {
                            onAttendance:
                              data.onAttendance ||
                              emp.details?.layoffSalaryRules?.onAttendance,
                            onLayOffLeave:
                              data.onLayOffLeave ||
                              emp.details?.layoffSalaryRules?.onLayOffLeave,
                            onNonLayOffLeave:
                              data.onNonLayOffLeave ||
                              emp.details?.layoffSalaryRules?.onNonLayOffLeave,
                            calculatedAgainst:
                              data.calculatedAgainst ||
                              emp.details?.layoffSalaryRules?.calculatedAgainst,
                          },
                        },
                      } as any);

                      onClose();
                    },
                    mapTToU: (b) => b,
                  });

                  return <Form />;
                },
              },
            },
          },
          {
            auth: 'UI:EDIT-EMP-PROFILE:VIEW',
            type: 'button',
            button: {
              name: 'Edit Shift Details',
              behaviour: 'modal',
              modal: {
                title: 'Edit Shift Details',
                content: ({ onClose }) => {
                  const Form = renderFormV2<any>({
                    style: 'md:w-1/3',
                    fieldsData: [
                      {
                        property: 'shiftType',
                        type: 'select',
                        required: true,
                        options: [
                          { value: '', text: 'select' },
                          { value: 'Rotational', text: 'Rotational' },
                          {
                            value: 'General',
                            text: 'General (Fixed Standard Shift)',
                          },
                          { value: 'Fixed', text: 'Fixed (Custom Shift)' },
                          {
                            value: 'Flexible',
                            text: 'Flexible (Does not require Shift)',
                          },
                        ],
                      },
                      {
                        property: 'generalShift',
                        type: 'searchable-select',
                        dependentOn: (d) => {
                          return d.shiftType === 'General';
                        },
                        searchOptions: {
                          searchOptionsBody: {
                            customBody: (data: ShiftScheduleType) => {
                              return (
                                <div>
                                  <div className='font-bold'>{data.name}</div>
                                  <div className='text-gray-600'>
                                    {data.location}
                                  </div>
                                </div>
                              );
                            },
                          },
                          filter: { location: emp.details?.workLocation },
                          useSearch: useShiftSchedules,
                          onSearchValueSelect: () => {},
                        },
                      },
                      {
                        property: 'shiftDuration',
                        type: 'number',
                        dependentOn: (d) => {
                          return d.shiftType === 'Fixed';
                        },
                      },
                      {
                        property: 'startTime',
                        type: 'input',
                        dependentOn: (d) => {
                          return d.shiftType === 'Fixed';
                        },
                      },
                      {
                        property: 'endTime',
                        type: 'input',
                        dependentOn: (d) => {
                          return d.shiftType === 'Fixed';
                        },
                      },
                      {
                        property: 'weeklyOff',
                        type: 'select',
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'NoWeekOff', value: 'NoWeekOff' },
                          { text: 'Sunday', value: 'Sunday' },
                          { text: 'Monday', value: 'Monday' },
                          { text: 'Tuesday', value: 'Tuesday' },
                          { text: 'Wednesday', value: 'Wednesday' },
                          { text: 'Thursday', value: 'Thursday' },
                          { text: 'Friday', value: 'Friday' },
                          { text: 'Saturday', value: 'Saturday' },
                        ],
                        dependentOn: (formData) => {
                          return ['Fixed', 'Flexible', 'General'].includes(
                            formData.shiftType as string
                          );
                        },
                      },
                      {
                        property: 'flexiHours',
                        type: 'input',
                        dependentOn: (formData) => {
                          return formData?.shiftType === 'Flexible';
                        },
                      },
                      { property: 'unionName', type: 'input' },
                    ],
                    initialFormState: {
                      weeklyOff: emp.details?.jobProfile?.weeklyOff || '',
                      shiftType: emp?.details?.jobProfile?.shiftType || '',
                      startTime: emp?.details?.jobProfile?.startTime || '',
                      endTime: emp?.details?.jobProfile?.endTime || '',
                      shiftDuration:
                        emp?.details?.jobProfile?.shiftDuration || '',
                      generalShift: emp?.details?.jobProfile?.generalShift || {
                        id: '',
                        name: '',
                      },
                      unionName: emp.details?.jobProfile?.unionName || '',
                    },
                    onSubmit: async (data) => {
                      console.log(data);
                      const jobProfile = emp.details.jobProfile || {};
                      const newJobProfile: any = {
                        shiftType: data.shiftType || jobProfile.shiftType,
                      };
                      if (newJobProfile.shiftType === 'Fixed') {
                        newJobProfile.startTime =
                          data.startTime || jobProfile.startTime;
                        newJobProfile.endTime =
                          data.endTime || jobProfile.endTime;
                        newJobProfile.shiftDuration = parseInt(
                          (
                            data.shiftDuration ||
                            jobProfile.shiftDuration ||
                            '0'
                          ).toString()
                        );
                      }
                      if (
                        ['Fixed', 'General', 'Flexible'].includes(
                          newJobProfile.shiftType
                        )
                      ) {
                        newJobProfile.weeklyOff =
                          data.weeklyOff || jobProfile.weeklyOff;
                      }

                      if (newJobProfile.shiftType === 'Flexible') {
                        newJobProfile.flexiHours =
                          data.flexiHours || jobProfile.flexiHours;
                      }

                      if (newJobProfile.shiftType === 'General') {
                        newJobProfile.generalShift = data.generalShift?.id
                          ? data.generalShift
                          : jobProfile?.generalShift;
                      }
                      newJobProfile.unionName =
                        data.unionName || jobProfile.unionName;

                      newJobProfile.role = jobProfile.role;
                      newJobProfile.roles = jobProfile.roles;
                      newJobProfile.skills = jobProfile.skills;
                      newJobProfile.responsibility = jobProfile.responsibility;
                      newJobProfile.skillLevel = jobProfile.skillLevel;

                      await setEmployee({
                        id: emp.id,
                        details: {
                          jobProfile: newJobProfile,
                        },
                      } as any);

                      onClose();
                    },
                    mapTToU: (b) => b,
                  });

                  return <Form />;
                },
              },
            },
          },
          {
            auth: 'UI:EDIT-EMP-PROFILE:VIEW',
            type: 'button',
            button: {
              name: 'Edit',
              behaviour: 'modal',
              modal: {
                title: 'Edit Profile',
                content: ({ onClose }) => {
                  const Form = renderFormV2<any>({
                    style: 'md:w-1/3',
                    fieldsData: [
                      { property: 'name', type: 'input' },
                      {
                        property: 'primaryCompany',
                        type: 'searchable-select',
                        searchOptions: {
                          useSearch: useCompanies,
                          onSearchValueSelect: () => {},
                        },
                      },
                      {
                        property: 'gender',
                        type: 'select',
                        required: true,
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'Male', value: 'Male' },
                          { text: 'Female', value: 'Female' },
                        ],
                      },
                      { property: 'department', type: 'input' },
                      { property: 'designation', type: 'input' },
                      // {
                      //   property: 'shiftType',
                      //   type: 'select',
                      //   required: true,
                      //   options: [
                      //     { value: '', text: 'select' },
                      //     { value: 'Rotational', text: 'Rotational' },
                      //     { value: 'General', text: 'General' },
                      //     { value: 'Fixed', text: 'Fixed' },
                      //     { value: 'Flexible', text: 'Flexible' },
                      //   ],
                      // },
                      // {
                      //   property: 'shiftDuration',
                      //   type: 'number',
                      //   dependentOn: (formData) => {
                      //     return formData.shiftType === 'Fixed';
                      //   },
                      // },
                      // {
                      //   property: 'startTime',
                      //   type: 'input',
                      //   dependentOn: (d) => {
                      //     return d.shiftType === 'Fixed';
                      //   },
                      // },
                      // {
                      //   property: 'endTime',
                      //   type: 'input',
                      //   dependentOn: (d) => {
                      //     return d.shiftType === 'Fixed';
                      //   },
                      // },
                      // { property: 'age', type: 'input' },
                      // { property: 'union', type: 'input' },
                      {
                        property: 'workLocation',
                        type: 'select',
                        options: [
                          { value: '', text: 'select' },
                          ...sisLocationNames.map((s) => ({
                            value: s,
                            text: s,
                          })),
                        ],
                        required: true,
                      },
                      {
                        property: 'grade',
                        type: 'searchable-select',
                        searchOptions: {
                          useSearch: useEmployeeCategories,
                          onSearchValueSelect: () => {},
                        },
                      },
                      // {
                      //   property: 'weeklyOff',
                      //   type: 'select',
                      //   options: [
                      //     { text: 'Select', value: '' },
                      //     { text: 'NoWeekOff', value: 'NoWeekOff' },
                      //     { text: 'Sunday', value: 'Sunday' },
                      //     { text: 'Monday', value: 'Monday' },
                      //     { text: 'Tuesday', value: 'Tuesday' },
                      //     { text: 'Wednesday', value: 'Wednesday' },
                      //     { text: 'Thursday', value: 'Thursday' },
                      //     { text: 'Friday', value: 'Friday' },
                      //     { text: 'Saturday', value: 'Saturday' },
                      //   ],
                      // },
                      // {
                      //   property: 'flexiHours',
                      //   type: 'input',
                      //   dependentOn: (formData) => {
                      //     return formData?.shiftType === 'Flexible';
                      //   },
                      // },
                      { property: 'noticePeriod', type: 'input' },
                      // { property: 'specialLeaves', type: 'input' },
                      { property: 'employeeId', type: 'input' },
                      {
                        property: 'salaryParam',
                        type: 'searchable-select',
                        searchOptions: {
                          useSearch: useSalaryParams,
                          onSearchValueSelect: () => {},
                        },
                      },
                      // {
                      //   property: 'esicApplicable',
                      //   type: 'select',
                      //   required: true,
                      //   label: 'ESIC Applicable',
                      //   options: [
                      //     { text: 'Select', value: '' },
                      //     { text: 'Yes', value: 'Yes' },
                      //     { text: 'No', value: 'No' },
                      //   ],
                      // },
                      {
                        property: 'pfApplicable',
                        type: 'select',
                        required: true,
                        label: 'PF Applicable',
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'Yes', value: 'Yes' },
                          { text: 'No', value: 'No' },
                        ],
                      },
                      // {
                      //   property: 'epsContributionPercent',
                      //   type: 'number',
                      //   label: 'EPS Contri Percent',
                      // },
                      {
                        property: 'onboardToAttendance',
                        type: 'select',
                        label: 'Onboard to Attendance',
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'Yes', value: 'yes' },
                          { text: 'No', value: 'no' },
                        ],
                      },
                      {
                        property: 'onboardToPayroll',
                        type: 'select',
                        label: 'Onboard to Payroll',
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'Yes', value: 'yes' },
                          { text: 'No', value: 'no' },
                        ],
                      },
                      {
                        property: 'nextSalaryRevision',
                        type: 'date',
                        label: 'Next Salary Revision',
                      },
                      {
                        property: 'newDepartment',
                        type: 'searchable-select',
                        searchOptions: {
                          useSearch: useDepartments,
                          onSearchValueSelect: () => {},
                        },
                        label: 'New Department',
                      },
                      { property: 'photo', type: 'file' },
                    ],
                    initialFormState: {
                      name: emp.name || '',
                      designation: emp.details?.designation || '',
                      newDepartment:
                        {
                          id: emp?.department?.id || '',
                          name: emp?.department?.name || '',
                        } || null, // Dept root field
                      department: emp.details?.department || '', // Dept in details field
                      primaryCompany: emp.company || { id: '', name: '' },
                      // primaryCompanyId: emp.company?.name || '',
                      contactDetails:
                        ((emp.details?.contactDetails as unknown) as string) ||
                        '',
                      noticePeriod: emp.details?.noticePeriod || '',
                      // union: ((emp.details?.union as unknown) as string) || '',
                      gender: emp.details?.gender || '',
                      birthDate: emp.details?.birthDate || '',
                      maritalStatus: emp.details?.maritalStatus || '',
                      retirementDate: emp.details?.retirementDate || '',
                      preferredLanguageForCorrespondence:
                        emp.details?.preferredLanguageForCorrespondence || '',
                      photo: emp.details?.photo || '',
                      // identificationMarks: emp.details?.identificationMarks || '',
                      // bloodGroup: emp.details?.bloodGroup || '',
                      // height: emp.details?.height || '',
                      // weight: emp.details?.weight || '',
                      // age: emp.details?.age || '',
                      grade: emp.details?.grade || { id: '', name: '' },
                      workLocation: emp.details?.workLocation || '',
                      category: emp.details?.category || '',
                      // specialLeaves: emp.details?.specialLeaves || '',
                      employeeId: emp.details?.employeeId || '',
                      // weeklyOff: emp.details?.jobProfile?.weeklyOff || '',
                      // esicApplicable: emp?.details?.esicApplicable || '',
                      // epsContributionPercent: emp?.details?.epsContributionPercent || 0,
                      pfApplicable: emp?.details?.pfApplicable || '',
                      // shiftType: emp?.details?.jobProfile?.shiftType || '',
                      // startTime: emp?.details?.jobProfile?.startTime || '',
                      // endTime: emp?.details?.jobProfile?.endTime || '',
                      // shiftDuration:
                      //   emp?.details?.jobProfile?.shiftDuration || '',
                      salaryParam: emp?.details?.salaryParam
                        ? emp.details.salaryParam
                        : '',
                      onboardToAttendance: emp?.details?.onboardToAttendance
                        ? emp.details.onboardToAttendance
                        : '',
                      onboardToPayroll: emp?.details?.onboardToPayroll || '',
                      nextSalaryRevision: emp?.details?.nextSalaryRevision
                        ? emp.details?.nextSalaryRevision
                        : '',
                    },
                    onSubmit: async (data) => {
                      // const jobProfile = emp.details.jobProfile || {};
                      // jobProfile.shiftType =
                      //   data.shiftType || jobProfile.shiftType;
                      // jobProfile.startTime =
                      //   data.startTime || jobProfile.startTime;
                      // jobProfile.endTime = data.endTime || jobProfile.endTime;
                      // jobProfile.weeklyOff =
                      //   data.weeklyOff || jobProfile.weeklyOff;
                      // jobProfile.shiftDuration = parseInt(
                      //   (
                      //     data.shiftDuration ||
                      //     jobProfile.shiftDuration ||
                      //     '0'
                      //   ).toString()
                      // );

                      // delete data.shiftType;
                      // delete data.startTime;
                      // delete data.endTime;
                      // delete data.weeklyOff;
                      // delete data.shiftDuration;

                      // data.epsContributionPercent = parseInt(
                      //   (
                      //     data.epsContributionPercent ||
                      //     emp.details.epsContributionPercent ||
                      //     '0'
                      //   ).toString()
                      // );
                      // data.jobProfile = jobProfile;

                      const dataReq = {
                        id: emp.id,
                        name: emp.name,
                        department: data?.newDepartment
                          ? data.newDepartment
                          : null,
                        details: data,
                      } as EmployeeProfileType;

                      if (
                        data.primaryCompany?.id &&
                        data.primaryCompany?.id !== emp.company?.id
                      ) {
                        (dataReq as any).replaceCompany = data.primaryCompany;
                      }

                      delete data.primaryCompany;

                      // console.log('this is dataReq', dataReq);
                      setEmployee(dataReq);

                      onClose();
                    },
                    mapTToU: (b) => b,
                    upload: {
                      useFileTransfer: usePublicTransfer,
                      config: [
                        {
                          name: 'photo',
                          path: `${companyGroup.id}/${company.id}/employees/${emp.id}`,
                          type: 'public',
                        },
                      ],
                    },
                  });

                  return <Form />;
                },
              },
            },
          },
        ],
      },
      body: {
        type: 'columns',
        body: [],
      },
    };

    const initialEmployeeData = {
      profileData: employeeProfileInitialData,
    };

    return (
      <div className='w-full space-y-3'>
        <CardComponent
          auth={{ actions: getAllowedActions().actions }}
          body={{
            type: 'columns',
            body: mapEmployeeProfileBody(emp, isHr),
          }}
          header={initialEmployeeData.profileData.header}
          bodyLoading={loading}
        />
      </div>
    );
  };
}

const mapEmployeeProfileBody = (
  data: EmployeeProfileType,
  isHr: boolean
): ColumnizedContent => {
  return [
    [
      {
        key: 'Employee Name:',
        value: data?.name,
        type: 'text',
      },
      {
        key: 'Company:',
        value: data?.company?.name || '--',
        type: 'text',
      },
      // {
      //   key: 'Department:',
      //   value: data?.details?.department || '--',
      //   type: 'text',
      // },
      {
        key: 'Designation:',
        value: data?.details?.designation || '--',
        type: 'text',
      },
      {
        key: 'Status',
        value: data?.status,
      },
      // {
      //   key: 'Age:',
      //   value: data?.details?.age?.toString() || '--',
      //   type: 'text',
      // },
      {
        key: 'Union:',
        value: data?.details?.jobProfile?.unionName || '--',
        type: 'text',
      },
      {
        key: 'Employee Id:',
        value: data?.details?.employeeId?.toString() || '--',
        type: 'text',
      },
      {
        key: 'Salary Param:',
        value: data?.details?.salaryParam?.name || '--',
        type: 'text',
      },
      {
        key: 'Next Salary Revision',
        value: data?.details?.nextSalaryRevision || '--',
        type: 'text',
      },
      {
        key: 'Notice Period:',
        value: `${data?.details?.noticePeriod?.toString() || '--'} days`,
        type: 'text',
      },
      // {
      //   key: 'ESIC Applicable:',
      //   value: data?.details?.esicApplicable || '--',
      //   type: 'text',
      // },
      // {
      //   key: 'EPS Contribution %:',
      //   value: data?.details?.epsContributionPercent?.toString() || '--',
      // },
    ],
    [
      {
        key: 'Work Location:',
        value: data?.details?.workLocation || '--',
        type: 'text',
      },
      // {
      //   key: 'Category:',
      //   value: data?.details?.category || '--',
      //   type: 'text'
      // },
      isHr
        ? {
            key: 'Grade:',
            value: data?.details?.grade?.name || '--',
            type: 'text',
          }
        : null,
      {
        key: 'Shift Type:',
        value: data?.details?.jobProfile?.shiftType || '?',
        type: 'text',
      },
      {
        key: 'General Shift:',
        value:
          data?.details?.jobProfile?.shiftType === 'General'
            ? data.details?.jobProfile?.generalShift?.name
            : 'NA',
        type: 'text',
      },
      {
        key: 'New Department:',
        value: data?.department?.name || '--',
        type: 'text',
      },
      {
        key: 'Flexi Hours:',
        value: data?.details?.jobProfile?.flexiHours?.toString() || 'NA',
        type: 'text',
      },
      {
        key: 'Weekly Off:',
        value: data?.details?.jobProfile?.weeklyOff || '--',
        type: 'text',
      },
      {
        key: 'Notice Period, Days:',
        value: data?.details?.noticePeriod?.toString() || '--',
        type: 'text',
      },
      // {
      //   key: 'Special Leave:',
      //   value: data?.details?.specialLeaves || '--',
      //   type: 'text',
      // },
      {
        key: 'ESIC Applicable:',
        value: data?.details?.esicApplicable || '--',
        type: 'text',
      },
      {
        key: 'PF Applicable:',
        value: data?.details?.pfApplicable || '--',
        type: 'text',
      },
      isHr
        ? {
            key: 'Onboard to Attendance:',
            value: data?.details?.onboardToAttendance || '--',
            type: 'text',
          }
        : null,
      isHr
        ? {
            key: 'Onboard To Payroll',
            value: data?.details?.onboardToPayroll || '--',
            type: 'text',
          }
        : null,
    ].filter((x) => x !== null) as any,
  ];
};
