import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  RotationalShiftDayType,
  SwitchShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import {
  MonthSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  useConfirm,
} from '@erp_core/erp-ui-components';
import {
  ArrowPathIcon,
  ArrowsUpDownIcon,
  ClockIcon,
  CogIcon,
  MagnifyingGlassCircleIcon,
  PencilIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { renderAuthWrapper } from '../../../../components/auth';
import { LocationContext } from '../../../../contexts/location';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseRotationalShiftDays } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { UseSwitchShift } from '../../../../hooks/hrd/switch-shift-schedule/use-switch-shift';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { UserRendererInterface } from '../../../common/fragments/user';
import { adjustEmployeeOvertime } from '../../attendance/leaves-book/forms/approve-leave-form';
import { AddShiftForm, AddShiftFormType } from './forms/add-shift-form';
import { renderChangeSupervisor } from './forms/change-supervisor';
import { SelectSwitchAdjustEmployeeForm } from './forms/select-switch-adjust-employee-form';
import { SelectSwitchEmployeeForm } from './forms/select-switch-employee-form';
import { SelectSwitchWeeklyOffEmployeeForm } from './forms/select-switch-weekly-off-form';

export type CreateEmployeeShiftsPageProps = {
  useEmployee: UseEmployeeProfile;
  useEmployees: UseEmployeeProfiles;
  useRotationalShiftDays: UseRotationalShiftDays;
  useRotationalShiftDay: UseRotationalShiftDay;
  useShiftSchedules: UseShiftSchedules;
  useOvertime: UseOvertime;
  useOvertimes: UseOvertimes;
  useGodowns: UseGodowns;
  useSwitchShift: UseSwitchShift;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
  useCurrentUserRoles: UseCurrentUserRoles;
};

export function createEmployeeShiftsPage({
  useEmployee,
  useGodowns,
  useEmployees,
  useRotationalShiftDays,
  useOvertime,
  useShiftSchedules,
  useOvertimes,
  useRotationalShiftDay,
  useSwitchShift,
  useUserAuthorization,
  userRendererService,
  useCurrentUserRoles,
}: CreateEmployeeShiftsPageProps): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();
  const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

  return function EmployeeShiftsPage(): JSX.Element {
    const { Confirm, openConfirm } = useConfirm();
    const { get: getAllowedActions } = useUserAuthorization();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const [isHr, setIsHr] = useState<boolean>(false);

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }
    }, [currentUserRoles]);

    const { getSync: getEmployeeSync } = useEmployee();
    const {
      data: schedules,
      getAll: getAllShiftSchedules,
    } = useShiftSchedules();
    const { syncSet: setSwitchShift } = useSwitchShift();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { syncSet: setOvertime } = useOvertime();
    const {
      data: shiftDays,
      getAll: getAllShiftDays,
      getAllSync: getSyncShiftDays,
    } = useRotationalShiftDays();
    const {
      syncSet: setRotationalShiftDay,
      delete: deleteShift,
    } = useRotationalShiftDay();
    const { data: overtimes, getAll: getAllOvertimes } = useOvertimes();
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);
    const { location: currentLocation, company: currentCompany } = useContext(
      LocationContext
    );
    const { user: currentUser } = useContext(UserContext);
    const [month, setMonth] = useState(
      localStorage.getItem('shift-month') || moment.utc().format('YYYY-MM')
    );
    const { getAll: getAllWorkAreas, data: workAreas } = useGodowns();
    useEffect(() => {
      getAllWorkAreas({
        type: 'work-area',
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      localStorage.setItem('shift-month', month);
      if (selectedEmployee.id) {
        getData();
      }
      // eslint-disable-next-line
    }, [month]);

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    useEffect(() => {
      getAllShiftSchedules();
      // eslint-disable-next-line
    }, []);

    const getData = () => {
      if (selectedEmployee.id) {
        getAllShiftSchedules();

        getAllShiftDays({
          employeeId: `equal::${selectedEmployee.id}`,
          from: `more-than::${month}-00`,
          to: `less-than::${month}-32`,
        });

        getAllOvertimes({
          employeeId: `equal::${selectedEmployee.id}`,
        });
      }
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, [selectedEmployee]);

    useEffect(() => {
      // console.log(overtimes);
    }, [overtimes]);

    const manualEdit = async (newData) => {
      const originalShift = shiftDays?.find((x) => x.date === newData.date);
      if (originalShift) {
        await setRotationalShiftDay({
          id: originalShift.id,
          details: {
            newShiftId: newData.shift,
          },
        } as any);

        await setSwitchShift({
          name: `${originalShift.employee.name}-${originalShift.employee.name}-${originalShift.date}`,
          currentEmployee: originalShift.employee,
          replaceEmployee: originalShift.employee,
          currentShiftDay: originalShift,
          replaceShiftDay: originalShift,
          status: 'pending',
          details: { reason: newData.reason, type: 'individual' },
          approvedBy: currentUser,
        } as SwitchShiftScheduleType);
        toast(
          'Change Edit request submitted successfully. Check Switch Shift!'
        );
      }
    };

    const switchShift = async (
      emp1Shift: RotationalShiftDayType,
      emp2Shift: RotationalShiftDayType,
      reason: string,
      type: 'company-mandated' | 'individual'
    ) => {
      console.log(emp1Shift, emp2Shift, reason, type);
      await setSwitchShift({
        name: `${emp1Shift.employee.name}-${emp2Shift.employee.name}-${emp1Shift.date}`,
        currentEmployee: emp1Shift.employee,
        replaceEmployee: emp2Shift.employee,
        currentShiftDay: emp1Shift,
        replaceShiftDay: emp2Shift,
        status: 'pending',
        details: { reason, type },
        approvedBy: currentUser,
      } as SwitchShiftScheduleType);
      toast('Switch Shift request submitted successfully!');
      getData();
    };

    //Replace Weekly Off logic
    const replaceWeeklyOff = async (
      emp1Shift: RotationalShiftDayType,
      emp2Shift: RotationalShiftDayType,
      emp1ReplaceShift: RotationalShiftDayType,
      emp2ReplaceShift: RotationalShiftDayType,
      reason: string,
      type: string
    ) => {
      openConfirm({
        title: 'Switch shift',
        message: ((
          <div>
            <div>Following will happen</div>
            <div className='text-center'>
              <div className='font-bold'>Date: {emp1Shift.date}</div>
              <div className='flex'>
                <div className='w-1/2 text-center border-r'>
                  <div>{emp1Shift.employee.name}</div>
                  <div>will be on weekly off</div>
                </div>
                <div className='w-1/2 text-center'>
                  <div>{emp2Shift.employee.name}</div>
                  <div>will be working</div>
                </div>
              </div>

              <div className='font-bold mt-3'>
                Replacement date: {emp1ReplaceShift.date}
              </div>
              <div className='flex'>
                <div className='w-1/2 text-center border-r'>
                  <div>{emp1ReplaceShift.employee.name}</div>
                  <div>will be working</div>
                </div>
                <div className='w-1/2 text-center'>
                  <div>{emp2ReplaceShift.employee.name}</div>
                  <div>will be on weekly off</div>
                </div>
              </div>
            </div>
          </div>
        ) as unknown) as string,
        onConfirm: async () => {
          // console.log(emp1Shift, emp2Shift, emp1ReplaceShift, emp2ReplaceShift, reason, type)
          const empsShiftData = {
            name: `${emp1Shift.employee.name}-${emp2Shift.employee.name}-${emp1Shift.date}`,
            currentEmployee: emp1Shift.employee,
            replaceEmployee: emp2Shift.employee,
            currentShiftDay: emp1Shift,
            replaceShiftDay: emp2Shift,
            status: 'pending',
            details: { reason, type },
            approvedBy: currentUser,
          };

          // console.log(empsShiftData)
          await setSwitchShift(empsShiftData as SwitchShiftScheduleType);

          const empsReplaceShiftData = {
            name: `${emp2ReplaceShift.employee.name}-${emp1ReplaceShift.employee.name}-${emp2ReplaceShift.date}`,
            currentEmployee: emp2ReplaceShift.employee,
            replaceEmployee: emp1ReplaceShift.employee,
            currentShiftDay: emp2ReplaceShift,
            replaceShiftDay: emp1ReplaceShift,
            status: 'pending',
            details: { reason, type },
            approvedBy: currentUser,
          };
          // console.log(empsReplaceShiftData)
          await setSwitchShift(empsReplaceShiftData as SwitchShiftScheduleType);

          toast('Weekly Switch Shift request submitted successfully!');
          getData();
        },
      });
    };

    const onAddShift = async (formData: AddShiftFormType) => {
      if (!formData.supervisor.id) {
        // Fetch the rotational shift supervisor for that day
        const shiftDays = await getSyncShiftDays({
          date: formData.date,
        });

        const supervisor = shiftDays.find(
          (x) =>
            x.details.role === 'supervisor' &&
            x.details?.shiftId?.id === formData.shift.id
        );
        if (supervisor) {
          formData.supervisor = supervisor.employee;
        }
      }

      if (formData.supervisor.id) {
        const final: Partial<RotationalShiftDayType> = {
          employee: { id: selectedEmployee.id, name: selectedEmployee.name },
          date: formData.date,
          supervisor: formData.supervisor,
          attendance: formData.attendance || 'working',
          details: {
            shiftId: formData.shift,
            scheduleId: '',
            role: 'employee',
            workArea: formData.workArea,
          },
        };
        await setRotationalShiftDay(
          (final as unknown) as RotationalShiftDayType
        );
      } else {
        toast.error('Could not find a valid supervisor');
      }
      getData();
    };

    const tableHeader = [
      [
        { name: 'Date' },
        { name: 'Attendance' },
        { name: 'Shift' },
        { name: 'WorkArea' },
        { name: 'Responsibility' },
        { name: 'Supervisor' },
      ],
    ];

    const tableBody: TableBody =
      _.sortBy(shiftDays || [], 'date').map((e) => {
        const sch = schedules?.find((x) => x.id === e.details.shiftId?.id);

        return {
          rowData: {
            sch,
            shiftDay: e,
          },
          cells: [
            { value: <div>{e.date} </div> },
            { value: <div>{e.attendance}</div> },
            {
              value:
                e.attendance === 'working' ? (
                  <div>
                    <div>{sch?.name}</div>
                    <div className='text-xs text-gray-800 font-thin'>
                      ({sch?.startTime || '?'} to {sch?.endTime || '?'})
                    </div>
                    {overtimes
                      ?.filter((x) => x.date === e.date)
                      .map((x) => (
                        <div key={x.id} className='bg-slate-50 my-0.5'>
                          <ClockIcon className='w-4 h-4 inline mx-1' />{' '}
                          {x.hours} hours overtime
                        </div>
                      ))}
                  </div>
                ) : (
                  <></>
                ),
            },
            {
              value:
                e.attendance === 'working' ? (
                  <div>{e.details.workArea?.name}</div>
                ) : (
                  <></>
                ),
            },
            {
              value: (
                <div>
                  {e.attendance === 'working' ? (
                    <div>
                      <span className='capitalize'>
                        {e.details.role === 'employee' ? (
                          <UserIcon className='w-5 inline text-blue-500' />
                        ) : null}
                        {e.details.role === 'supervisor' ? (
                          <MagnifyingGlassCircleIcon className='w-5 inline text-blue-500' />
                        ) : null}
                        {e.details.role === 'employee'
                          ? e.details.roleName
                          : e.details.role}{' '}
                        Role
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ),
            },
            {
              value: (
                <div>
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={e.supervisor.id}
                    name={e.supervisor.name}
                  />
                </div>
              ),
            },
          ],
        };
      }) || [];
    return (
      <div className='pb-52'>
        <Card
          auth={{ actions: getAllowedActions().actions }}
          header={{
            title: `Employee Shift: ${
              selectedEmployee.id ? selectedEmployee.name : 'None Selected'
            }`,
            subheading: (
              <div className='flex items-center'>
                {selectedEmployee?.details?.jobProfile?.shiftType
                  ? `Shift ${selectedEmployee?.details?.jobProfile?.shiftType}`
                  : ''}
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>Select Month</span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
              {
                type: 'button',
                show: () => {
                  return selectedEmployee?.id ? true : false;
                },
                auth: 'UI:BTN-EDIT-SINGLE-SHIFT:VIEW',
                button: {
                  name: 'Add Shift',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Shift',
                    content: ({ onClose }) => (
                      <AddShiftForm
                        isHr={isHr}
                        currentCompany={currentCompany}
                        currentLocation={currentLocation}
                        onSubmit={async (form) => {
                          await onAddShift(form);
                          onClose();
                        }}
                        workAreas={workAreas || []}
                        useWorkAreas={useGodowns}
                        useShifts={useShiftSchedules}
                        avoidDates={shiftDays?.map((s) => s.date) || []}
                        supervisor={
                          selectedEmployee.details.authorizations
                            ?.attendanceSupervisor || { id: '', name: '' }
                        }
                      />
                    ),
                  },
                },
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='flex p-1 w-80 space-x-2 items-center justify-between font-bolder'>
                    <div className='font-bold flex-1'>Select</div>
                    <div>
                      <SearchBox />
                    </div>
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {tableBody.length ? (
                  <div>
                    {/* <AddShiftButton /> */}
                    <Table
                      header={tableHeader}
                      body={tableBody}
                      auth={{ actions: getAllowedActions().actions }}
                      actions={[
                        {
                          name: 'Switch shift',
                          icon: (
                            <ArrowPathIcon className='w-5 h-5 text-blue-500 inline cursor-pointer hover:text-blue-700' />
                          ),
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return shiftDay.attendance !== 'weekly-off';
                          },
                          auth: 'UI:BTN-SWITCH-SHIFT:VIEW',
                          behaviour: 'modal',
                          modal: {
                            title: 'Switching employees shifts',
                            content: ({ data: { shiftDay }, onClose }) => {
                              return (
                                <div>
                                  <SelectSwitchEmployeeForm
                                    onClose={onClose}
                                    date={shiftDay.date}
                                    emp1Shift={shiftDay}
                                    useEmployees={useEmployees}
                                    useRotationalShiftDays={
                                      useRotationalShiftDays
                                    }
                                    schedules={schedules}
                                    switchShift={switchShift}
                                  />
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: 'Assign Overtime',
                          icon: (
                            <CogIcon className='w-5 h-5 inline text-pink-500 cursor-pointer hover:text-pink-700' />
                          ),
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return shiftDay.attendance !== 'weekly-off';
                          },
                          auth: 'UI:BTN-ADJUST-SHIFT:VIEW',
                          behaviour: 'modal',
                          modal: {
                            title: `Adjusting overtime for ${selectedEmployee.name}'s unavailability`,
                            content: ({ data: { shiftDay }, onClose }) => {
                              return (
                                <div>
                                  <SelectSwitchAdjustEmployeeForm
                                    targetedEmployee={selectedEmployee}
                                    markCurrentEmployeeOnLeave={false}
                                    editCurrentEmployeeOnLeave={true}
                                    date={shiftDay.date}
                                    emp1Shift={shiftDay}
                                    useEmployees={useEmployees}
                                    useRotationalShiftDays={
                                      useRotationalShiftDays
                                    }
                                    schedules={schedules}
                                    adjustEmployeeOvertime={(props) => {
                                      adjustEmployeeOvertime(props, {
                                        currentUser,
                                        setOvertime,
                                        getEmployeeSync,
                                        setRotationalShiftDay,
                                        openConfirm,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: 'Switch with Weekly off',
                          icon: (
                            <ArrowsUpDownIcon className='w-5 h-5 inline text-green-500 cursor-pointer hover:text-green-700' />
                          ),
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return shiftDay.attendance !== 'weekly-off';
                          },
                          auth: 'UI:BTN-SWAP-WEEKOFF:VIEW',
                          behaviour: 'modal',
                          modal: {
                            title: `Switching ${selectedEmployee.name}'s weekly off ()`,
                            content: ({ data: { shiftDay }, onClose }) => {
                              return (
                                <AuthWrapper
                                  action='UI:BTN-SWAP-WEEKOFF:VIEW'
                                  children={
                                    <div>
                                      <SelectSwitchWeeklyOffEmployeeForm
                                        onClose={onClose}
                                        date={shiftDay.date}
                                        emp1Shift={shiftDay}
                                        useEmployees={useEmployees}
                                        useRotationalShiftDays={
                                          useRotationalShiftDays
                                        }
                                        schedules={schedules}
                                        //Replace weekly off new logic is passed
                                        replaceWeeklyOffs={replaceWeeklyOff}
                                      />
                                    </div>
                                  }
                                />
                              );
                            },
                          },
                        },
                        {
                          name: 'Edit Single Shift',
                          icon: (
                            <PencilIcon className='w-5 h-5 inline text-blue-500 cursor-pointer hover:text-blue-700' />
                          ),
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return shiftDay.attendance !== 'weekly-off';
                          },
                          auth: 'UI:BTN-EDIT-SINGLE-SHIFT:VIEW', // UI:BTN-SWAP-WEEKOFF:VIEW
                          behaviour: 'modal',
                          modal: {
                            title: 'Edit Shift',
                            content: ({ data: { shiftDay }, onClose }) => {
                              return (
                                <AddShiftForm
                                  isHr={isHr}
                                  currentCompany={currentCompany}
                                  currentLocation={currentLocation}
                                  isEditMode={true}
                                  initialData={{
                                    date: shiftDay.date,
                                    shift: shiftDay.details.shiftId,
                                    attendance: shiftDay.attendance,
                                    workArea: shiftDay.details?.workArea,
                                    supervisor: shiftDay.supervisor?.name,
                                    reason: '',
                                  }}
                                  onSubmit={async (form) => {
                                    await manualEdit(form);
                                    onClose();
                                  }}
                                  workAreas={workAreas || []}
                                  useWorkAreas={useGodowns}
                                  useShifts={useShiftSchedules}
                                  avoidDates={
                                    shiftDays?.map((s) => s.date) || []
                                  }
                                  supervisor={
                                    selectedEmployee.details.authorizations
                                      ?.attendanceSupervisor || {
                                      id: '',
                                      name: '',
                                    }
                                  }
                                />
                              );
                            },
                          },
                        },
                        {
                          name: 'Change Supervisor to Employee Role',
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return (
                              shiftDay.attendance !== 'weekly-off' &&
                              shiftDay.details?.role === 'employee'
                            );
                          },
                          behaviour: 'confirm',
                          icon: (
                            <UserPlusIcon className='w-5 h-5 inline text-purple-500 cursor-pointer hover:text-purple-700' />
                          ),
                          auth: 'UI:BTN-CHG-SUP:VIEW',
                          onConfirm: ({ shiftDay }) => {
                            return {
                              title:
                                'Are your sure you want to change employee role to supervisor?',
                              onConfirm: async () => {
                                await setRotationalShiftDay({
                                  id: shiftDay.id,
                                  details: {
                                    role: 'supervisor',
                                  },
                                } as any);
                                toast.success(
                                  `${shiftDay.employee.name} was updated to Supervisor role...`
                                );
                              },
                            };
                          },
                        },
                        {
                          name: 'Change Supervisor',
                          show: ({
                            shiftDay,
                          }: {
                            shiftDay: RotationalShiftDayType;
                          }) => {
                            return shiftDay.attendance !== 'weekly-off';
                          },
                          auth: 'UI:BTN-CHG-SUP:VIEW',
                          icon: (
                            <MagnifyingGlassCircleIcon className='w-5 h-5 inline text-pink-500 cursor-pointer hover:text-pink-700' />
                          ),
                          behaviour: 'modal',
                          modal: {
                            title: 'Change Supervisor',
                            content: ({ data: { shiftDay }, onClose }) => {
                              const ChangeSupervisor = renderChangeSupervisor();

                              return (
                                <div>
                                  <ChangeSupervisor
                                    form={{
                                      date: shiftDay.date,
                                      name: shiftDay.employee.name,
                                      supervisor: shiftDay.supervisor,
                                    }}
                                    saveShift={async (form) => {
                                      if (form.supervisor?.id) {
                                        await setRotationalShiftDay({
                                          id: shiftDay.id,
                                          supervisor: form.supervisor,
                                        } as RotationalShiftDayType);
                                      }
                                      onClose();
                                    }}
                                    useEmployees={useEmployees}
                                  />
                                </div>
                              );
                            },
                          },
                        },
                        {
                          name: 'Delete',
                          show: ({ shiftDay }) => {
                            return shiftDay ? true : false;
                          },
                          auth: 'UI:BTN-DEL-SHFT:VIEW',
                          behaviour: 'confirm',
                          onConfirm: ({ shiftDay }) => {
                            return {
                              type: 'warning',
                              onConfirm: async () => {
                                deleteShift(shiftDay.id);
                              },
                              message:
                                'Are you sure you want to delete the Shift',
                            };
                          },
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <div className='mx-auto p-5 text-center text-xl'>
                    {selectedEmployee.id ? (
                      <>
                        <div>
                          <div>
                            Seems like {selectedEmployee.name} does not have any
                            Schedule set!
                          </div>
                          {/* <AddShiftButton /> */}
                        </div>
                      </>
                    ) : (
                      <>Select an Employee to see Schedule</>
                    )}
                  </div>
                )}
              </div>
            ),
          }}
        />
        <Confirm />
      </div>
    );
  };
}
